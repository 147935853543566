import { useEffect, useState } from 'react';
import { Parser } from 'html-to-react';
import {
    createAppointment,
    createLock,
    getCalendarDates,
    getAppointmentState,
    getService,
    deleteLock
} from '../data/public';
import { getAppointmentFields } from '../data/public';
import { Alert, Button, Card, Col, Modal, Row } from 'react-bootstrap';
import Loading from '../components/loading';
import BaseForm from '../components/base-form';
import { convertSecondsToTime, formatDate, formatTime } from '../data/utils';
import { t } from '../data/localization';

const Service = ({ locale = 'en', id = null }) => {
    const [showAlert, setShowAlert] = useState(false);
    const [variant, setVariant] = useState('warning');
    const [message, setMessage] = useState('');
    const [step, setStep] = useState('landing');
    const [service, setService] = useState(null);
    const [calendarDates, setCalendarDates] = useState();
    const [selectedCalendar, setSelectedCalendar] = useState();
    const [selectedCalendarDate, setSelectedCalendarDate] = useState(null);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
    const [appointment, setAppointment] = useState(null);
    const [appointmentFields, setAppointmentFields] = useState(null);
    const [duration, setDuration] = useState(null);
    const [clientCount, setClientCount] = useState(null);
    const [lockedAt, setLockedAt] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [intervalId, setIntervalId] = useState(null);
    const [confirmation, setConfirmation] = useState(null);

    useEffect(() => {
        getService(id, locale).then((res) => {
            setService(res);
            setClientCount(parseInt(res.defaultClientCount));
            setDuration(parseInt(res.defaultDuration));
        }).catch(e => {
            setMessage(e.toString());
            setVariant('danger');
            setShowAlert(true);
        });
    }, []);

    useEffect(() => {
        if (showModal) {
            deleteLock(appointment.lockToken);
        }
    }, [showModal]);

    useEffect(() => {
        setShowAlert(false);
        switch (step) {
            case 'landing':
            case 'calendar':
                setAppointment(null);
                setSelectedCalendar(null);
                setSelectedCalendarDate(null);
                setSelectedTimeSlot(null);
                break;
            case 'date':
                setAppointment(null);
                setSelectedCalendarDate(null);
                setSelectedTimeSlot(null);
                break;
            case 'timeslot':
                setAppointment(null);
                setSelectedTimeSlot(null);
                break;
            case 'confirmation':
                clearInterval(intervalId);
                break;
            case 'error':
                clearInterval(intervalId);
                break;
            default:
                break;
        }
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant',
        });
    }, [step]);

    useEffect(() => {
        if (lockedAt && selectedCalendar && selectedCalendar.lockTime <= 600) {
            let interval = setInterval(() => {
                const secondsElapsed = parseInt(Math.abs((new Date().getTime() - lockedAt) / 1000));
                const secondsLeft = (selectedCalendar.lockTime - secondsElapsed);
                if (secondsLeft <= 0) {
                    clearInterval(interval);
                    setShowModal(true);
                } else {
                    const timeLeft = convertSecondsToTime(secondsLeft, false);
                    setMessage(t('Time Left:', locale) + ' ' + timeLeft);

                    if (variant !== 'info' || !showAlert) {
                        setVariant('info');
                        setShowAlert(true);
                    }
                }
            }, 1000);
            setIntervalId(interval);
        }
    }, [lockedAt]);

    useEffect(() => {
        if (appointment) {
            const newAppointmentFields = getAppointmentFields(
                service, appointment, locale
            );
            setAppointmentFields(newAppointmentFields);
        }
    }, [appointment]);

    if (service === null) {
        return <Loading />;
    }

    const handleRestartClick = () => {
        setShowModal(false);
        setStep('landing');
    };

    const handleAppointmentChange = (data) => {
        const endTime = parseInt(data.startTime) + parseInt(data.duration);
        setAppointment({
            ...data,
            endTime: endTime
        });
    };

    const handleSubmit = () => {
        const data = {
            ...appointment,
            startTime: convertSecondsToTime(appointment.startTime),
            endTime: convertSecondsToTime(appointment.endTime)
        };
        createAppointment(data).then(res => {
            setConfirmation(res);
            setStep('confirmation');
        }).catch(e => {
            setStep('error');
            setVariant('danger');
            setMessage(t('An error occurred. Please try again', locale));
            setShowAlert(true);
        });
    };

    const renderRestartButton = () => {
        return <Button variant="primary" size="lg" onClick={handleRestartClick}>{t('Restart', locale)}</Button>;
    };

    const renderStep = () => {
        if (step === 'landing') {
            return (
                <>
                    <Row>
                        <Col>
                            {Parser().parse(service.landingPage)}
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col xs={6}>
                            <Button variant="primary" className="w-100" onClick={() => setStep('calendar')} type="button" size="lg">
                                {t('Next', locale)}
                            </Button>
                        </Col>
                    </Row>
                </>
            );
        }

        if (step === 'calendar') {
            const handleCalendarClick = (calendar) => {
                setSelectedCalendar(calendar);
                window.scrollTo({
                    top: document.body.scrollHeight,
                    left: 0,
                    behavior: 'smooth',
                });
            };

            const handleSubmit = () => {
                getCalendarDates(id, selectedCalendar.id, duration).then(res => {
                    setCalendarDates(res.items);
                    setStep('date');
                }).catch(e => {
                    setMessage(e.toString());
                    setVariant('danger');
                    setShowAlert(true);
                });
            };

            const customSubmitElement = (
                <Row>
                    <Col xs={6}>
                        <Button variant="primary" className="w-100" onClick={() => setStep('landing')} type="button" size="lg">
                            {t('Back', locale)}
                        </Button>
                    </Col>
                    {selectedCalendar ?
                        <Col xs={6}>
                            <Button variant="primary" className="w-100" type="submit" size="lg">
                                {t('Next', locale)}
                            </Button>
                        </Col> : ''}
                </Row>
            );

            const field = {
                name: 'selectedCalendar',
                type: 'custom',
                element: <Row>
                    {service.calendars.map((calendar, index) => {
                        const isSelected = (selectedCalendar !== null && selectedCalendar === calendar);
                        return (
                            <Col key={index} sm={6}>
                                <Card bg="light" className="mb-3" role="button" border={isSelected ? 'primary' : ''} onClick={() => handleCalendarClick(calendar)}>
                                    <Card.Body>
                                        <Card.Title className="text-center">{calendar.location.name}</Card.Title>
                                        {!calendar.location.isVirtual
                                            ? <Card.Text>
                                                <p>{calendar.location.address}<br />
                                                    {calendar.location.city}, {calendar.location.province}<br />
                                                    {calendar.location.postalCode}<br />
                                                    {calendar.location.phone}
                                                </p>
                                            </Card.Text> : ''}
                                    </Card.Body>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            };

            return (
                <>
                    <p><strong>{t('Select a clinic:', locale)}</strong></p>
                    <BaseForm
                        fields={[field]}
                        formState={{ selectedCalendar: selectedCalendar }}
                        validFormCallback={handleSubmit}
                        customSubmitElement={customSubmitElement}
                    />
                </>
            );
        }

        if (step === 'date' && calendarDates && selectedCalendar) {
            const handleCalendarDateClick = (calendarDate) => {
                setSelectedCalendarDate(calendarDate);
                window.scrollTo({
                    top: document.body.scrollHeight,
                    left: 0,
                    behavior: 'smooth',
                });
            };

            const handleBackButton = () => {
                setStep('calendar');
            };

            const customSubmitElement = (
                <Row>
                    <Col xs={6}>
                        <Button variant="primary" className="w-100" onClick={handleBackButton} type="button" size="lg">
                            {t('Back', locale)}
                        </Button>
                    </Col>
                    {selectedCalendarDate ?
                        <Col xs={6}>
                            <Button variant="primary" className="w-100" type="submit" size="lg">
                                {t('Next', locale)}
                            </Button>
                        </Col> : ''}
                </Row>
            );

            const handleSubmit = () => {
                setStep('timeslot');
            };

            const field = {
                name: selectedCalendarDate,
                type: 'custom',
                element: <Row>
                    {calendarDates.length > 0 ?
                        calendarDates.map((calendarDate, index) => {
                            const isSelected = (selectedCalendarDate !== null && selectedCalendarDate === calendarDate);
                            return (
                                <Col key={index} sm={4} className="mb-2">
                                    <Card bg="light" role="button" border={isSelected ? 'primary' : ''}
                                        onClick={(e) => handleCalendarDateClick(calendarDate)}>
                                        <Card.Body>
                                            <Card.Title className="text-center">{formatDate(calendarDate.date, locale)}</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            );
                        })
                        : <p>{t('There are no dates available.', locale)}</p>}
                </Row>
            };

            return (
                <>
                    <p><strong>{t('Select a date:', locale)}</strong></p>
                    <BaseForm
                        fields={[field]}
                        formState={{ selectedCalendarDate: selectedCalendarDate }}
                        validFormCallback={handleSubmit}
                        customSubmitElement={customSubmitElement}
                    />
                </>
            );
        }

        if (step === 'timeslot' && calendarDates && selectedCalendarDate) {
            const handleTimeSlotClick = (timeSlot) => {
                setSelectedTimeSlot(timeSlot);
                window.scrollTo({
                    top: document.body.scrollHeight,
                    left: 0,
                    behavior: 'smooth',
                });
            };

            const handleBackButton = () => {
                setStep('calendar');
            };

            const customSubmitElement = (
                <Row>
                    <Col xs={6}>
                        <Button variant="primary" className="w-100" onClick={handleBackButton} type="button" size="lg">
                            {t('Back', locale)}
                        </Button>
                    </Col>
                    {selectedCalendarDate ?
                        <Col xs={6}>
                            <Button variant="primary" className="w-100" type="submit" size="lg">
                                {t('Next', locale)}
                            </Button>
                        </Col> : ''}
                </Row>
            );

            const handleSubmit = () => {
                createLock(selectedTimeSlot.time, selectedTimeSlot.id, duration).then(res => {
                    setLockedAt(new Date().getTime());
                    setSelectedTimeSlot(selectedTimeSlot);
                    const data = getAppointmentState(selectedTimeSlot, duration, clientCount, res.token, locale);
                    setAppointment({ ...data, ...[] });
                    setStep('form');
                }).catch(e => {
                    getCalendarDates(id, selectedCalendar.id, duration).then(res => {
                        setSelectedCalendarDate(res.items.find(_ => _.date === selectedCalendarDate.date));
                        setMessage(t('Unavailable time slot. Please select another one.', locale));
                        setVariant('danger');
                        setShowAlert(true);
                    }).catch(e => {
                        setMessage(e.toString());
                        setVariant('danger');
                        setShowAlert(true);
                    });
                });
            };

            const field = {
                name: selectedCalendarDate,
                type: 'custom',
                element: <Row>
                    {selectedCalendarDate.timeslots.map((timeSlot, index) => {
                        const isSelected = (selectedTimeSlot !== null && selectedTimeSlot === timeSlot);
                        return (
                            <Col sm={3} key={index}>
                                <Card bg="light" role="button" border={isSelected ? 'primary' : ''} className="mb-2" onClick={(e) => handleTimeSlotClick(timeSlot)}>
                                    <Card.Body>
                                        <Card.Title className="text-center">{formatTime(timeSlot.time, locale)}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            };

            return (
                <>
                    <p><strong>{t('Select a time:', locale)}</strong></p>
                    <BaseForm
                        fields={[field]}
                        formState={{ selectedTimeSlot: selectedTimeSlot }}
                        validFormCallback={handleSubmit}
                        customSubmitElement={customSubmitElement}
                    />
                </>
            );
        }

        if (step === 'form' && appointment && appointmentFields) {
            return (
                <Row className="mb-5">
                    <p><strong>{t('Please fill all required fields below (fields marked by an * are mandatory and must be completed).', locale)}</strong></p>
                    <p>{t('Date and Time:', locale)} <strong>{formatDate(selectedCalendarDate.date, locale)} {t('at', locale)} {formatTime(convertSecondsToTime(appointment.startTime), locale)}</strong></p>
                    <BaseForm
                        enableRecaptcha={true}
                        fields={appointmentFields}
                        formState={appointment}
                        setFormStateCallback={handleAppointmentChange}
                        submitButtonLabel={t('Proceed with Booking', locale)}
                        validFormCallback={handleSubmit}
                    />
                </Row>
            );
        }

        if (step === 'confirmation') {
            const handleClick = () => {
                window.print();
            };
            const confirmationPage = service.confirmationPage
                .replace('[CODE]', confirmation.confirmationCode.toUpperCase())
                .replace('[DATE]', formatDate(selectedCalendarDate.date, locale))
                .replace('[TIME]', formatTime(selectedTimeSlot.time, locale))
                .replace(
                    '[LOCATION]',
                    selectedCalendar.location.address + ', ' +
                    selectedCalendar.location.city + ', ' +
                    selectedCalendar.location.postalCode
                )
                .replace('[CLIENTS]', appointment.clientData.map(_ => _.preferredName ? _.firstName + ' ' + _.lastName + ' (' + _.preferredName + ')' : _.firstName + ' ' + _.lastName).join(', '));
            return (
                <Row>
                    <Col>
                        <img className="d-block my-2 img-fluid mx-auto d-print-none" src="/success-checkmark.png" title="success" height="25%" />
                      {Parser().parse(confirmationPage)}
                      {service.removePrintButton ? <></> :
                       <Button variant="primary" className="w-100 d-print-none" size="lg" onClick={handleClick}>{t('Print', locale)}</Button>}
                    </Col>
                </Row>
            );
        }

        if (step === 'error') {
            return renderRestartButton();
        }

        return <></>;
    };

    return (
        <>
            <h1 className="text-center">{service.title} - {t('Appointment Booking', locale)}</h1>
            <Alert className="text-center d-print-none" show={showAlert} variant={variant}>{message}</Alert>
            <Row className={'py-3 step-container-' + step}>
                {renderStep()}
            </Row>
            <Modal
                show={showModal}
                onHide={handleRestartClick}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('Session Expired', locale)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t('Your session has expired. Click restart to book a new appointment.', locale)}
                </Modal.Body>
                <Modal.Footer>
                    {renderRestartButton()}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Service;
