import { sendRequest } from './api';
import { t } from './localization';
import { PHONE_REGEX } from './utils';
const BASE_URL = 'appointments';
export const codes = {
    All: [
        '7SBP',
        'ANF',
        'B-C',
        'C19',
        'CT',
        'E-C',
        'H-C',
        'HR',
        'ISPA',
        'IZA',
        'PCY',
        'PCY-T',
        'PS',
        'R011',
        'R1264',
        'R65',
        'RV',
        'ST-T',
        'TB-R',
        'TB-T'
    ],
    IMM: [
        '7SBP',
        'ANF',
        'HR',
        'IZA',
        'PCY',
        'PS',
        'R011',
        'R1264',
        'R65',
        'RV'
    ],
    TB: [
        'ANF',
        'PCY',
        'RV',
        'TB-R',
        'TB-T'
    ],
    YAC: [
        'B-C',
        'CT',
        'E-C',
        'H-C',
        'PCY',
        'PCY-T',
        'ST-T'
    ]
};

const PHONE_REMINDER_NONE = 0;
const PHONE_REMINDER_PRIMARY_VOICE = 1;
const PHONE_REMINDER_PRIMARY_SMS = 2;
const PHONE_REMINDER_SECONDARY_VOICE = 3;
const PHONE_REMINDER_SECONDARY_SMS = 4;
const PHONE_REMINDER_CUSTOM_VOICE = 5;
const PHONE_REMINDER_CUSTOM_SMS = 6;
const PHONE_REMINDER_OPTIONS = [
    {
        label: 'No reminder',
        value: PHONE_REMINDER_NONE
    },
    {
        label: 'Voice - Primary Phone Number',
        value: PHONE_REMINDER_PRIMARY_VOICE
    },
    {
        label: 'SMS - Primary Phone Number',
        value: PHONE_REMINDER_PRIMARY_SMS
    },
    {
        label: 'Voice - Secondary Phone Number',
        value: PHONE_REMINDER_SECONDARY_VOICE
    },
    {
        label: 'SMS - Secondary Phone Number',
        value: PHONE_REMINDER_SECONDARY_SMS
    },
    {
        label: 'Voice - Alternate Phone Number',
        value: PHONE_REMINDER_CUSTOM_VOICE
    },
    {
        label: 'SMS - Alternate Phone Number',
        value: PHONE_REMINDER_CUSTOM_SMS
    }
];
export const getClientFields = (filteredFields = [], locale = 'en') => {
    let codesOptions = Object.keys(codes)
        .reduce((a, v) => [...a, ...codes[v]], [])
        .filter((v, i, a) => a.indexOf(v) === i)
        .map(v => { return { label: v, value: v }; });

    if (filteredFields.length > 0) {
        const codesField = filteredFields.find(item => item.name === 'codes');
        if (codesField && codesField.options) {
            codesOptions = codesOptions.filter(item => codesField.options.find(option => option === item.value));
        }
    }

    const currentDate = new Date();

    const clientFields = [
        {
            name: 'firstName',
            label: t('First name', locale),
            placeholder: t('Enter first name', locale),
            type: 'text',
            required: true
        },
        {
            name: 'lastName',
            label: t('Last name', locale),
            placeholder: t('Enter last name', locale),
            type: 'text',
            required: true
        },
        {
            name: 'clientId',
            label: 'Client ID',
            type: 'text',
            pattern: '[0-9]{10}',
            maxLength: 10,
            required: false
        },
        {
            name: 'preferredName',
            label: t('Preferred name (if different from what appears on health card)', locale),
            placeholder: t('Enter preferred name', locale),
            type: 'text',
            required: false
        },
        {
            name: 'pronoun',
            label: t('Preferred pronoun', locale),
            placeholder: t('Select preferred pronoun', locale),
            type: 'select',
            required: false,
            showListing: true,
            showForm: true,
            options: [
                {
                    label: t('He', locale),
                    value: 'he'
                },
                {
                    label: t('She', locale),
                    value: 'she'
                },
                {
                    label: t('They', locale),
                    value: 'they'
                }
            ]
        },
        {
            name: 'dateOfBirth',
            label: t('Date of birth', locale),
            placeholder: t('Enter date of birth', locale),
            type: 'date',
            showAge: true,
            maxDate: new Date(currentDate.getFullYear(), 12, 31),
            required: false
        },
        {
            name: 'legalGuardian',
            label: t('Legal guardian/substitute decision maker first and last name (if applicable)', locale),
            placeholder: t('Enter first and last name', locale),
            type: 'text',
            required: false
        },
        {
            name: 'codes',
            label: t('Codes', locale),
            placeholder: t('Enter a codes', locale),
            type: 'multiselect',
            required: false,
            showSelectToggle: false,
            showListing: false,
            showForm: true,
            options: codesOptions
        },
        {
            name: 'dose',
            label: t('Dose #', locale),
            placeholder: t('Select dose', locale),
            type: 'number',
            required: false
        },
        {
            name: 'attendance',
            label: t('Client attendance', locale),
            placeholder: t('Select a status', locale),
            type: 'select',
            groupEdit: true,
            required: false,
            options: [
                {
                    label: t('Arrived', locale),
                    value: 'arrived'
                },
                {
                    label: t('Left', locale),
                    value: 'left'
                },
                {
                    label: t('No Show', locale),
                    value: 'noshow'
                },
            ]
        },
        {
            name: 'initials',
            label: t('Initials', locale),
            placeholder: t('Enter initials', locale),
            type: 'text',
            groupEdit: true,
            maxLength: 2,
            required: false
        },
        {
            name: 'room',
            label: t('Nurse/Room', locale),
            placeholder: t('Enter nurse/room', locale),
            type: 'text',
            groupEdit: true,
            required: false
        },
        {
            name: 'notGuardian',
            label: t('Individual accompanying client is NOT the parent or legal guardian', locale),
            type: 'checkbox',
            required: false
        },
        {
            name: 'authorized',
            label: t('Authorized by the program manager', locale),
            type: 'checkbox',
            required: false
        },
        {
            name: 'translationServices',
            label: t('Translation Services Required', locale),
            type: 'checkbox',
            required: false
        }
    ];

    if (filteredFields.length > 0) {
        return clientFields
            .filter(current => filteredFields.find(item => item.name === current.name) || current.required)
            .map(current => current.required ? current : { ...current, required: filteredFields.find(item => item.name === current.name).required });
    }

    return clientFields;
};

export const getAppointmentFields = (
    calendar,
    availableDurations = [],
    appointmentState
) => {
    const clientFields = getClientFields(calendar.fields);
    let fields = [
        {
            name: 'duration',
            label: 'Duration',
            placeholder: '',
            type: 'select',
            required: true,
            min: 1,
            max: 10,
            options: availableDurations
        },
        {
            name: 'clientData',
            label: 'Client Information',
            placeholder: '',
            singularName: 'Client Information',
            type: 'repeater',
            required: true,
            showListing: false,
            showForm: true,
            frontend: true,
            min: 1,
            max: appointmentState.groupSession ? 10 : 1,
            addButtonLabel: 'Add Client to Group',
            fields: clientFields,
            defaultState: (appointmentState.groupSession && appointmentState.clientData.length > 0)
                ? { ...appointmentState.clientData[0], id: null } : getDefaultClientState(calendar.fields)
        },
        {
            name: 'language',
            label: 'Preferred Language',
            labelFr: 'Langue préférée',
            placeholder: 'Select preferred language',
            placeholderFr: 'Veuillez choisir une langue préférée',
            type: 'select',
            required: true,
            showListing: true,
            showForm: true,
            options: [
                {
                    label: 'English',
                    value: 'en'
                },
                {
                    label: 'French',
                    value: 'fr'
                }
            ]
        },
        {
            name: 'phone',
            label: 'Primary phone number',
            placeholder: 'Enter primary phone number (10 digits)',
            title: "Enter a valid 10-digit phone number",
            type: 'tel',
            maxLength: 12,
            pattern: PHONE_REGEX,
            required: true
        },
        {
            name: 'phoneDoNotCall',
            label: 'Do Not Call Primary Number',
            type: 'checkbox',
            required: false
        },
        {
            name: 'phoneAlt',
            label: 'Secondary phone number',
            placeholder: 'Enter the secondary phone number (10 digits)',
            title: "Enter a valid 10-digit phone number",
            maxLength: 12,
            pattern: PHONE_REGEX,
            type: 'tel'
        },
        {
            name: 'phoneAltDoNotCall',
            label: 'Do Not Call Secondary Number',
            type: 'checkbox',
            required: false
        },
        {
            name: 'phoneReminderType',
            label: 'Voice/SMS Reminder Type',
            type: 'select',
            placeholder: 'Select a value',
            required: true,
            options: PHONE_REMINDER_OPTIONS
        },
        {
            name: 'email',
            label: 'Email',
            placeholder: 'Enter email address',
            type: 'email',
            required: false
        }
    ];

    if (calendar.groupSessionEnabled) {
	fields.splice(
            1,
            0,
            {
		name: 'groupSession',
		label: 'Group Session: This option allows you to add multiple people to the appointment. SELECT THE CORRECT APPOINTMENT DURATION!',
		type: 'checkbox',
		required: false
	    }
        );
    }

    if (calendar.emailsEnabled) {
        fields = [...fields, ...[
            {
                name: 'sendConfirmationEmail',
                label: 'Confirmation Email',
                placeholder: 'If active, the client will receive a confirmation email.',
                type: 'checkbox',
                required: false
            },
            {
                name: 'sendReminderEmail',
                label: 'Reminder Email',
                placeholder: 'If active, the client will receive a reminder email.',
                type: 'checkbox',
                required: false
            }
        ]];

        /* Add in custom phone number for the reminder */
        if (
            parseInt(appointmentState.phoneReminderType) === PHONE_REMINDER_CUSTOM_VOICE ||
            parseInt(appointmentState.phoneReminderType) === PHONE_REMINDER_CUSTOM_SMS
        ) {
            const phoneReminderCustom = {
                name: 'phoneReminderCustom',
                label: 'Voice/SMS Reminder Phone Number',
                placeholder: 'Enter primary phone number (10 digits)',
                title: "Enter a valid 10-digit phone number",
                maxLength: 12,
                pattern: PHONE_REGEX,
                type: 'tel',
                required: true
            };
            const phoneReminderIndex = fields.findIndex(x => x.name === 'phoneReminderType') + 1;
            fields.splice(
                phoneReminderIndex,
                0,
                phoneReminderCustom
            );
        }

        /* Hide primary phone number options if do not call is checked */
        if (appointmentState.phoneDoNotCall) {
            fields = fields.map(x => x.name === 'phoneReminderType' ? {
                ...x,
                options: PHONE_REMINDER_OPTIONS.filter(
                    y => y.value !== PHONE_REMINDER_PRIMARY_VOICE &&
                        y.value !== PHONE_REMINDER_PRIMARY_SMS
                )
            } : x);
        }

        /* Hide secondary phone number options if do not call is checked */
        if (appointmentState.phoneAltDoNotCall) {
            fields = fields.map(x => x.name === 'phoneReminderType' ? {
                ...x, options: PHONE_REMINDER_OPTIONS.filter(
                    y => y.value !== PHONE_REMINDER_SECONDARY_VOICE &&
                        y.value !== PHONE_REMINDER_SECONDARY_SMS)
            } : x);
        }

        if (
            appointmentState.sendConfirmationEmail ||
            appointmentState.sendReminderEmail
        ) {
            fields.find(x => x.name === 'email').required = true;
        }
    }

    return fields;
};

export const getGroupEditFields = () => {

    return getClientFields().filter(x => x.groupEdit);
};

export const getDefaultAppointmentState = (lane, timeInSeconds, duration = null, lockToken, clientFields = []) => {
    const endTimeInSeconds = timeInSeconds + duration;
    const properties = {
        calendarDateLaneId: parseInt(lane.id),
        startTime: timeInSeconds,
        duration: duration,
        groupSession: false,
        endTime: endTimeInSeconds,
        email: null,
        phone: null,
        phoneDoNotCall: false,
        phoneAlt: null,
        phoneAltDoNotCall: false,
        phoneReminderType: 0,
        phoneReminderCustom: null,
        sendConfirmationEmail: false,
        sendReminderEmail: false,
        clientData: [getDefaultClientState(clientFields)],
        agreeValidInfo: false,
        agreePhone: false,
        lockToken: lockToken
    };

    return properties;
};

export const getDefaultClientState = (clientFields = []) => {
    return {
        ...{
            firstName: null,
            lastName: null,
            phone: null
        },
        ...clientFields
            .map(x => x.name)
            .reduce((o, key) => ({ ...o, [key]: (key === 'codes') ? [] : null }), {})
    };
};

export const getAppointment = (id) => {
    return sendRequest(BASE_URL + '/' + id, 'GET');
};

export const createAppointment = (data) => {
    return sendRequest(BASE_URL, 'POST', data);;
};

export const updateAppointment = (appointment) => {
    return sendRequest(BASE_URL + '/' + appointment.id, 'PUT', appointment);
};

export const deleteAppointment = (appointmentId) => {
    return sendRequest(BASE_URL + '/' + appointmentId, 'DELETE');
};

export const getAppointments = (params) => {
    const url = BASE_URL + (params ? '?' + new URLSearchParams(params).toString() : '');
    return sendRequest(url, 'GET');
};
