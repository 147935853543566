import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Alert, Button, Form } from 'react-bootstrap';
import CalendarLanes from '../components/calendar-lanes';
import {
    getCalendarDate,
    updateCalendarDate
} from '../data/calendar';
import TopNav from '../components/top-nav';
import { formatDate } from '../data/utils';
import Loading from '../components/loading';

const CalendarDateEditRoute = () => {
    const { id, date } = useParams();
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [calendarDate, setCalendarDate] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [variant, setVariant] = useState('');

    const handleSubmit = (e) => {
        const form = e.currentTarget;
        e.preventDefault();
        e.stopPropagation();
        setValidated(true);

        if (form.checkValidity()) {
            const response = updateCalendarDate(id, date, calendarDate);
            response.then(data => {
                const state = {
                    message: 'The calendar date has been updated.',
                    variant: 'success'
                };
                navigate('/admin/calendars/' + id + '/dates', { state: state });
            }).catch(e => {
                setMessage(e.toString());
                setVariant('danger');
                setShowAlert(true);
            });
        }
    };

    const handleLanesUpdate = (newLanes) => {
        setCalendarDate({ ...calendarDate, lanes: newLanes });
    };

    useEffect(() => {
        const response = getCalendarDate(id, date);
        response.then(data => {
            setCalendarDate(data);
        });
    }, []);

    if (calendarDate === null) {
        return <Loading />;
    }
    
    const previousItems = [
        {
            label: 'Calendars',
            link: '/admin/calendars'
        },
        {
            label: 'Calendar Dates',
            link: '/admin/calendars/' + id + '/dates'
        }
    ];

    return (
        <>
          <TopNav activeItem="Edit Calendar Date" previousItems={previousItems} />
          <h1>Edit Calendar Date - {formatDate(calendarDate.date)}</h1>
            <Alert variant="warning">
                <Alert.Heading>Warning</Alert.Heading>
              <p>It is <strong>not recommended</strong> to edit a calendar date if there are appointments already scheduled. Proceed with caution.</p>
            </Alert>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <CalendarLanes calendarLanes={calendarDate.lanes} handleStateChange={handleLanesUpdate} />
                <div className="d-grid gap-2">
                    <Button variant="primary" type="submit" size="lg">
                        Save Calendar Date
                    </Button>
                </div>
            </Form>
        </>
    );
};

export default CalendarDateEditRoute;
