import { useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import moment from 'moment';
import { displayAge, formatPhone } from '../data/utils';

const FormField = ({ index, field, fieldValue, readOnly = false, setFieldValue }) => {
    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    const setChildFieldValue = (childFieldName, newValue) => {
        const newArray = {};
        newArray[childFieldName] = newValue;

        if (typeof fieldValue === 'undefined') {
            setFieldValue(newArray);
        } else {
            const updatedArray = { ...fieldValue, ...newArray };
            setFieldValue(updatedArray);
        }
    };


    const handleDeleteConfirmationAction = () => {
        const newFieldValue = fieldValue.filter(current => current !== itemToDelete);
        setFieldValue(newFieldValue);
        handleDeleteConfirmationDialogClose();
    };

    const handleDeleteConfirmationDialogClose = () => {
        setShowDeleteConfirmationDialog(false);
        setItemToDelete(null);
    };

    const handleRepeaterAdd = (e) => {
        e.preventDefault();
        setFieldValue([...fieldValue, field.defaultState]);
    };

    const handleRepeaterDelete = (item) => {
        setItemToDelete(item);
        setShowDeleteConfirmationDialog(item);
    };

    const handleRepeaterUpdate = (item, newValue) => {
        const newFieldValue = fieldValue.map(current => {
            if (item === current) {
                return { ...current, ...newValue };
            }

            return current;
        });

        setFieldValue(newFieldValue);
    };

    const handleChange = (e) => {
        if (typeof field.onChangeHook === 'function') {
            const filteredValue = field.onChangeHook(e);
            setFieldValue(filteredValue);
        } else if (e.target.type === 'checkbox') {
            setFieldValue(e.target.checked);
        } else {
            setFieldValue(e.target.value);
        }
    };

    const handleMultiSelectDropdownChange = (newValues) => {
        if (typeof field.onChangeHook === 'function') {
            const filteredValue = field.onChangeHook(newValues);
            setFieldValue(filteredValue);
        } else {
            setFieldValue(newValues);
        }
    };

    const handleMultiSelectChange = (optionValue, isChecked) => {
        if (typeof field.onChangeHook === 'function') {
            const filteredValue = field.onChangeHook(optionValue, isChecked);
            setFieldValue(filteredValue);
        } else {
            if (isChecked && !fieldValue.find(item => item === optionValue)) {
                setFieldValue([...fieldValue, optionValue].sort());
            } else {
                setFieldValue(fieldValue.filter(item => item !== optionValue));
            }
        }
    };

    const renderField = (field) => {
        if (field.type === 'select') {
            return (
                <Form.Select
                    required={field.required}
                    disabled={readOnly}
                    aria-label={field.placeholder}
                    value={fieldValue}
                    onChange={handleChange}>
                    <option value="">{field.placeholder}</option>
                    {field.options.map((option, index) =>
                        <option key={index} value={option.value}>{option.label}</option>
                    )}
                </Form.Select>
            );
        }

        if (field.type === 'multiselect') {
            return <>
                {field.showSelectToggle
                    ? <div className="py-2">
                        <Button onClick={() => setFieldValue(field.options.map(x => x.value))}
                                variant="secondary" size="sm">Select All</Button>{' '}
                        {fieldValue.length > 0
                            ? <Button onClick={() => setFieldValue([])} variant="secondary" size="sm">Unselect All</Button>
                            : ''
                        }
                    </div>
                    : ''
                }
                {field.options.map((option, optionIndex) => {
                    const isChecked = fieldValue.length > 0 ? fieldValue.includes(option.value) : false;
                    return (
                        <Form.Check
                            key={index + optionIndex}
                            label={option.label}
                            checked={isChecked}
                            onChange={(e) => handleMultiSelectChange(option.value, e.target.checked)}
                        />
                    );
                })}
            </>;
        }

        if (field.type === 'multiselect-dropdown') {
            return (
                <DropdownMultiselect
                  optionKey="value"
                  options={field.options}
                  selected={fieldValue}
                  showSelectToggle={false}
                  handleOnChange={handleMultiSelectDropdownChange}
                />
            );
        }

        if (field.type === 'checkbox') {
            return (
                <Form.Check type="checkbox">
                  <Form.Check.Input disabled={readOnly} type="checkbox" onChange={handleChange} checked={fieldValue} required={field.required} />
                  <Form.Check.Label>{field.label}</Form.Check.Label>
                  <Form.Control.Feedback>{field.feedback}</Form.Control.Feedback>
                </Form.Check>
            );
        }

        if (field.type === 'form') {
            return (
                <Card className="mb-3">
                    <Card.Header>
                        <h4 className="float-start pt-2">{field.label}</h4>
                    </Card.Header>
                    <Card.Body>
                        <Form.Group>
                            {field.fields.map((childField, childFieldIndex) => {
                                let childFieldValue = '';
                                if (typeof fieldValue[childField.name] === 'undefined') {
                                    childFieldValue = field.defaultState[childField.name];
                                } else {
                                    childFieldValue = fieldValue[childField.name];
                                }

                                return <FormField
                                    key={index + childFieldIndex}
                                    index={field.name}
                                    field={childField}
                                    fieldValue={childFieldValue}
                                    readOnly={readOnly}
                                    setFieldValue={(newValue) => setChildFieldValue(childField.name, newValue)}
                                />;
                            })}
                        </Form.Group>
                    </Card.Body>
                </Card>
            );
        }

        if (field.type === 'repeater') {
            return (
                <Row>
                    <Col xs={12}>
                        {fieldValue.map((item, subIndex) => {
                            return (
                                <Card className="my-2">
                                    {fieldValue.length > field.min ?
                                        <Card.Header>
                                            <h4 className="float-start pt-2">{field.singularName}</h4>

                                            <Button variant="danger" className="float-end" onClick={() => handleRepeaterDelete(item)}>
                                                Delete
                                            </Button>
                                        </Card.Header>
                                        : ''}
                                    <Card.Body>
                                        <Row key={subIndex}>
                                            {field.fields.map((subField, subFieldIndex) =>
                                                <FormField
                                                    key={index + subIndex + subFieldIndex}
                                                    index={subIndex}
                                                    field={subField}
                                                    readOnly={readOnly}
                                                    fieldValue={item[subField.name]}
                                                    setFieldValue={(newValue) => handleRepeaterUpdate(item, { [subField.name]: newValue })}
                                                />
                                            )}
                                        </Row>
                                    </Card.Body>
                                </Card>
                            );
                        })}
                        {!readOnly && fieldValue.length < field.max ?
                            <Button onClick={handleRepeaterAdd}>
                              {field.addButtonLabel ?? 'Add'}
                            </Button> : ''}
                    </Col>
                    <Modal
                        show={showDeleteConfirmationDialog}
                        onHide={handleDeleteConfirmationDialogClose}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Deletion Confirmation
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                Are you sure you want to delete this item?
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={handleDeleteConfirmationAction}>Yes</Button>
                            <Button onClick={handleDeleteConfirmationDialogClose}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>
                </Row>
            );
        }

        if (field.type === 'textarea') {
            return (
                <Form.Control
                    as="textarea"
                    readOnly={readOnly}
                    value={fieldValue}
                    required={field.required}
                    onChange={handleChange}
                    placeholder={field.placeholder}
                    style={{ height: field.height + 'px' }}
                />
            );
        }

        if (field.type === 'custom') {
            return field.element;
        }

        if (field.type === 'date') {
            if (typeof fieldValue === 'string') {
                fieldValue = moment(fieldValue).utc().toDate();
            }
            return (
                <>
                  <DatePicker
                    yearPlaceholder="YYYY"
                    monthPlaceholder="MM"
                    dayPlaceholder="DD"
                    minDate={field.minDate}
                    maxDate={field.maxDate}
                    format="yy-MM-dd"
                    onChange={x => setFieldValue(x)}
                    value={fieldValue}
                    required={field.required} />
                  {field.showAge && fieldValue ? <p className="mb-0 mt-2">{displayAge(fieldValue, moment())}</p> : ''}
                </>
            );
        }

        if (field.type === 'tel') {
            if (fieldValue !== null) {
                fieldValue = formatPhone(fieldValue);
            }
        }

        return (
            <Form.Control readOnly={readOnly}
                          type={field.type}
                          minLength={field.minLength ? field.minLength : ''}
                          maxLength={field.maxLength ? field.maxLength : ''}
                          required={field.required}
                          placeholder={field.placeholder}
                          value={fieldValue ?? ''}
                          title={field.title}
                          pattern={field.pattern}
                          onChange={handleChange} />
        );
    };

    return (
        <Form.Group className="mb-3" controlId={field.name + index}>
          {!['form', 'checkbox', 'custom'].includes(field.type) && field.label
           ? <Form.Label>{field.label}{field.required ? <span className="asterisk">*</span> : ''}</Form.Label>
           : ''
          }
          {renderField(field)}
        </Form.Group>
    );
};

export default FormField;
