import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getCalendars } from '../data/calendar';
import {
    BASE_URL,
    updateService,
    createService,
    getService,
    getDefaultServiceState
} from '../data/service';
import { Alert } from 'react-bootstrap';
import BaseForm from '../components/base-form';
import AvailableFields from '../components/available-fields';
import TopNav from '../components/top-nav';

const ServiceEditRoute = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [variant, setVariant] = useState('');
    const [message, setMessage] = useState('');
    const [calendars, setCalendars] = useState([]);
    const [service, setService] = useState(null);

    const handleChange = (newService) => {
        setService(newService);
    };

    const handleSubmit = () => {
        const response = id ? updateService(id, service) : createService(service);
        response.then(() => {
            const state = {
                message: 'The service has been saved.',
                variant: 'success'
            };
            navigate(BASE_URL, { state: state });
        }).catch(e => {
            setMessage(e.toString());
            setVariant('danger');
            setShowAlert(true);
        });
    };

    useEffect(() => {
        const params = {
            enabled: true,
            lanes: true,
            location: true,
            category: true
        };
        getCalendars(params).then((response) => {
            setCalendars(response.items);

            if (id) {
                getService(id).then((response) => {
                    setService(response);
                }).catch(e => {
                    setMessage(e.toString());
                    setVariant('danger');
                    setShowAlert(true);
                });
            } else {
                setService(getDefaultServiceState(response.items[0]));
            }
        }).catch(e => {
            setMessage(e.toString());
            setVariant('danger');
            setShowAlert(true);
        });
    }, []);

    const conditionOptions = [
        {
            label: 'Value is less than',
            value: 'lt'
        },
        {
            label: 'Value is greater than',
            value: 'gt'
        },
        {
            label: 'Value is equal to',
            value: 'eq'
        },
        {
            label: 'Value is not equal to',
            value: 'neq'
        }
    ];

    const actionOptions = [
        {
            label: 'Go to question (use identifier)',
            value: 'goto-question'
        },
        {
            label: 'Go to end (use identifier)',
            value: 'goto-end'
        },
        {
            label: 'Go to next section',
            value: 'goto-next-section'
        },
        {
            label: 'Set appointment duration in minutes',
            value: 'set-duration'
        },
        {
            label: 'Set earliest date',
            value: 'set-earliest-date'
        },
        {
            label: 'Increase earliest by X months',
            value: 'add-months-earliest-date'
        },
        {
            label: 'Set client count',
            value: 'set-client-count'
        }
    ];

    const renderTitle = () => {
        if (service && service.id) {
            return 'Edit ' + service.title;
        }

        return 'New Service';
    };

    const renderForm = () => {
        if (!calendars || !service) {
            return <></>;
        }

        const getDurationOptions = () => {
            let options = [];

            calendars.forEach(calendar => {
                calendar.lanes.forEach(lane => {
                    lane.durations.forEach(duration => {
                        const value = parseInt(duration.value);
                        if (!options.includes(value)) {
                            options.push(value);
                        }
                    });
                });
            });

            return options
                .sort((a, b) => a > b)
                .map(current => {
                    return {
                        label: (current / 60) + ' minutes',
                        value: current
                    };
                });
        };

        const fields = [
            {
                name: 'title',
                label: 'Title',
                placeholder: '',
                type: 'text',
                required: false,
                tab: 'General'
            },
            {
                name: 'titleFr',
                label: 'Title (FR)',
                placeholder: '',
                type: 'text',
                required: false,
                tab: 'General'
            },
            {
                name: 'description',
                label: 'Description',
                placeholder: '',
                type: 'text',
                required: false,
                tab: 'Content'
            },
            {
                name: 'descriptionFr',
                label: 'Description (FR)',
                placeholder: '',
                type: 'text',
                required: false,
                tab: 'Content'
            },
            {
                name: 'landingPage',
                label: 'Landing Page',
                placeholder: '',
                type: 'textarea',
                required: false,
                height: 600,
                tab: 'Content'
            },
            {
                name: 'landingPageFr',
                label: 'Landing Page (FR)',
                placeholder: '',
                type: 'textarea',
                required: false,
                height: 600,
                tab: 'Content'
            },
            {
                name: 'emailContent',
                label: 'Email Content',
                placeholder: '',
                type: 'textarea',
                required: false,
                height: 300,
                tab: 'Content'
            },
            {
                name: 'emailContentFr',
                label: 'Email Content (FR)',
                placeholder: '',
                type: 'textarea',
                required: false,
                height: 300,
                tab: 'Content'
            },
            {
                name: 'confirmationPage',
                label: 'Confirmation Page',
                placeholder: '',
                type: 'textarea',
                required: false,
                height: 600,
                tab: 'Content'
            },
            {
                name: 'confirmationPageFr',
                label: 'Confirmation Page (FR)',
                placeholder: '',
                type: 'textarea',
                required: false,
                height: 600,
                tab: 'Content'
            },
            {
                name: 'calendarIds',
                label: 'Available Calendars',
                placeholder: 'Select one or more calendars',
                type: 'multiselect',
                required: true,
                tab: 'Calendars',
                options: calendars.map(item => {
                    return {
                        label: item.location.city + ' - ' + item.category.name + ' - ' + item.name,
                        value: item.id
                    };
                })
            },
            {
                name: 'enabled',
                label: 'Enabled',
                type: 'checkbox',
                required: false,
                tab: 'General'
            },
            {
                name: 'isPublic',
                label: 'Public',
                type: 'checkbox',
                required: false,
                tab: 'General'
            },
            {
                name: 'consentRequired',
                label: 'Consent Required',
                type: 'checkbox',
                required: false,
                tab: 'General'
            },
            {
                name: 'removePhoneDoNotCall',
                label: 'Remove Phone Do Not Call',
                type: 'checkbox',
                required: false,
                tab: 'General'
            },
            {
                name: 'removePhoneAltDoNotCall',
                label: 'Remove Secondary Phone Do Not Call',
                type: 'checkbox',
                required: false,
                tab: 'General'
            },
            {
                name: 'removePhoneReminderType',
                label: 'Remove Phone Reminder Type',
                type: 'checkbox',
                required: false,
                tab: 'General'
            },
            {
                name: 'removePrintButton',
                label: 'Remove Print Button',
                type: 'checkbox',
                required: false,
                tab: 'General'
            },
            {
                name: 'minDaysAhead',
                label: 'Minimum number of days ahead',
                placeholder: 'Enter a number',
                type: 'number',
                required: true,
                tab: 'General'
            },
            {
                name: 'maxDaysAhead',
                label: 'Maximum number of days ahead',
                placeholder: 'Enter a number',
                type: 'number',
                required: true,
                tab: 'General'
            },
            {
                name: 'defaultDuration',
                label: 'Default Duration',
                placeholder: '',
                type: 'select',
                required: true,
                tab: 'General',
                options: getDurationOptions()
            },
            {
                name: 'defaultClientCount',
                label: 'Default Number of Clients',
                placeholder: '',
                type: 'number',
                required: true,
                tab: 'General'
            },
            {
                name: 'fields',
                label: 'Fields',
                placeholder: 'Fields',
                type: 'custom',
                required: false,
                element: <AvailableFields
                    availableFields={service.fields}
                    handleStateChange={(newFields) => { handleChange({ ...service, fields: newFields }); }} />,
                tab: 'General'
            }
        ];

        return (
            <BaseForm fields={fields}
                formState={service}
                setFormStateCallback={handleChange}
                submitButtonLabel="Save"
                validFormCallback={handleSubmit}
            />);
    };

    const previousItems = [
        {
            label: 'Services',
            link: '/admin/services'
        }
    ];

    return (
        <>
            <TopNav activeItem={renderTitle()} previousItems={previousItems} />
            <h1>{renderTitle()}</h1>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            {renderForm()}
        </>
    );
};

export default ServiceEditRoute;
