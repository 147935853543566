import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Alert, Button, Form, Table } from 'react-bootstrap';
import CalendarLanes from '../components/calendar-lanes';
import { getCalendar, createCalendarDates, editCalendarDates } from '../data/calendar';
import Loading from '../components/loading';
import { formatDate } from '../data/utils';
import TopNav from '../components/top-nav';

const CalendarDatesBatchRoute = ({ mode = "Add" }) => {
    const { id } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [calendarLanes, setCalendarLanes] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [variant, setVariant] = useState('');
    const [showDates, setShowDates] = useState(false);

    const handleSubmit = (e) => {
        const form = e.currentTarget;
        e.preventDefault();
        e.stopPropagation();
        setValidated(true);

        if (form.checkValidity()) {
            const params = {
                dates: state,
                lanes: calendarLanes
            };
            const response = mode === 'Add' ? createCalendarDates(id, params) : editCalendarDates(id, params);
            response.then(res => {
                const state = {
                    message: 'The calendar date(s) have been ' + (mode === 'Add' ? 'created.' : 'modified.'),
                    variant: 'success'
                };
                navigate('/admin/calendars/' + id + '/dates', { state: state });
            }).catch(e => {
                setMessage(e.toString());
                setVariant('danger');
                setShowAlert(true);
            });
        }
    };

    useEffect(() => {
        const params = {
            lanes: true
        };
        const response = getCalendar(id, params);
        response.then(data => {
            setCalendarLanes(data.lanes);
        }).catch(e => {
            setMessage(e.toString());
            setVariant('danger');
            setShowAlert(true);
        });
    }, []);

    const renderDateTable = () => {
        return (
            <Table striped bordered hover >
                <thead>
                    <tr>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {state.map(x =>
                        <tr>
                            <td>{formatDate(x)}</td>
                        </tr>
                    )}
                </tbody>
            </Table >
        );
    };

    if (calendarLanes === null) {
        return <Loading />;
    }

    const previousItems = [
        {
            label: 'Calendars',
            link: '/admin/calendars'
        },
        {
            label: 'Calendar Dates',
            link: '/admin/calendars/' + id + '/dates'
        }
    ];

    return (
        <>
            <TopNav activeItem="Add Calendar Dates" previousItems={previousItems} />
            <h1>{mode} Calendar Dates</h1>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            <p><strong>{state.length}</strong> date(s) to {mode.toLowerCase()}.</p>
            <Button className="mb-3" variant="primary" type="button" onClick={() => setShowDates(!showDates)}>
                {showDates ? 'Hide' : 'Show'} Date(s)
            </Button>
            {showDates ? renderDateTable() : <></>}
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <CalendarLanes calendarLanes={calendarLanes} handleStateChange={(lanes) => setCalendarLanes(lanes)} />
                <div className="d-grid gap-2">
                    <Button variant="primary" type="submit" size="lg">
                        Save Calendar Date(s)
                    </Button>
                </div>
            </Form>
        </>
    );
};

export default CalendarDatesBatchRoute;
