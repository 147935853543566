import { useEffect } from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    Form,
    Table,
    ToggleButton
} from 'react-bootstrap';
import { getClientFields, codes } from '../data/appointment';
import FormField from './form-field';

const AvailableFields = ({ availableFields = [], handleStateChange = null }) => {
    const codeCategories = Object.keys(codes);
    const appointmentFields = getClientFields().filter(item => !item.required);

    const handleStatusChange = (availableMetaField, isChecked) => {
        const availableField = availableFields.find(item => item.name === availableMetaField.name);

        if (isChecked && typeof availableField === 'undefined') {
            const newAvailableFields = [...availableFields, { name: availableMetaField.name, required: true }];
            handleStateChange(newAvailableFields);
        } else {
            const newAvailableFields = availableFields.filter(item => item.name !== availableMetaField.name);
            handleStateChange(newAvailableFields);
        }
    };

    const handleRequiredChange = (availableMetaField, isChecked) => {
        const availableField = availableFields.find(item => item.name === availableMetaField.name);

        if (isChecked && typeof availableField === 'undefined') {
            const newAvailableFields = [...availableFields, { name: availableMetaField.name, required: true }];
            handleStateChange(newAvailableFields);
        } else {
            const newAvailableFields = availableFields.map(item => {
                if (item.name === availableMetaField.name) {
                    item.required = isChecked;
                }
                return item;
            });
            handleStateChange(newAvailableFields);
        }
    };

    const handleCategoryChange = (newCategory) => {
        const codesField = availableFields.find(item => item.name === 'codes');
        const newAvailableFields = availableFields.map(item => {
            if (item === codesField) {
                return {
                    ...item,
                    category: newCategory,
                    options: newCategory === 'All' ? [] : codes[newCategory]
                };
            }
            return item;
        });
        handleStateChange(newAvailableFields);
    };

    const renderCodes = () => {
        const codesField = availableFields.find(item => item.name === 'codes');
        if (!codesField || !codesField.category) {
            return <></>;
        }
        const currentCategory = codesField.category ? codesField.category : 'All';
        const categoryCodes = codes[currentCategory];
        const currentCodes = currentCategory === 'All' ? codesField.options : categoryCodes;
        const label = currentCategory === 'All'
              ? 'Select at least one value.'
              : 'These values cannot be changed. Click \'All\' to select individual values.';
        const availableCodesField = {
            name: 'availableCodes',
            label: label,
            type: 'multiselect',
            options: categoryCodes.map(x => {
                return {
                    label: x,
                    value: x
                };
            })
        };
        const handleCodesUpdates = (newCodes) => {
            const codesField = availableFields.find(item => item.name === 'codes');
            const newAvailableFields = availableFields.map(item => {
                if (item === codesField) {
                    return {
                        ...item,
                        options: newCodes
                    };
                }
                return item;
            });
            handleStateChange(newAvailableFields);
        };

        return (
            <Card>
                <Card.Header>
                    <h3>Code Categories</h3>
                </Card.Header>
                <Card.Body>
                    <ButtonGroup className="mb-2">
                        {codeCategories.map((v, i) =>
                            <ToggleButton
                              type="radio"
                              variant="secondary"
                              key={i}
                              id={'tbg-btn-' + i}
                              value={v}
                              checked={v === currentCategory}
                              onChange={() => handleCategoryChange(v)}
                              >
                              {v}
                            </ToggleButton>
                        )}
                    </ButtonGroup>
                    <FormField
                        key="codes"
                        index="codes"
                        field={availableCodesField}
                        fieldValue={currentCodes}
                        readOnly={false}
                        setFieldValue={handleCodesUpdates}
                    />
                </Card.Body>
            </Card>
        );
    };

    useEffect(() => {
        const codesField = availableFields.find(item => item.name === 'codes');

        if (codesField && !codesField.category) {
            handleCategoryChange('All');
        }
    }, [availableFields]);

    return (
        <>
            <p><strong>First name</strong> and <strong>last name</strong> are mandatory fields.</p>
            <Table bordered responsive>
                <thead>
                    <tr>
                        <th>Field</th>
                        <th>Enabled</th>
                        <th>Mandatory</th>
                    </tr>
                </thead>
                <tbody>
                    {appointmentFields.map((appointmentField, index) => {
                        const availableField = availableFields.find(field => field.name === appointmentField.name);
                        const enabled = (availableField) ? true : false;
                        const required = (availableField) ? availableField.required : false;

                        return (
                            <tr key={index}>
                                <td>
                                    {appointmentField.label}
                                </td>
                                <td>
                                    <Form.Check type="switch" checked={enabled} onChange={(e) => handleStatusChange(appointmentField, e.target.checked)} />
                                </td>
                                <td>
                                    <Form.Check type="switch" checked={required} onChange={(e) => handleRequiredChange(appointmentField, e.target.checked)} />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            {renderCodes()}
        </>
    );
};

export default AvailableFields;
