import Header from '../components/frontend/header';
import Footer from '../components/frontend/footer';
import Service from '../components/service';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { t } from '../data/localization';

const ServiceRoute = () => {
    const { locale, id } = useParams();
    return (
        <main role="main" className="frontend">
            <Header locale={locale} />
            <Container>
                <Row className="justify-content-md-center">
                    <Col lg={8}>
                        <p className="text-end">
                            <a href={t('ALT_LINK', locale)}>{t('ALT_LANG', locale)}</a>
                        </p>
                        <Service locale={locale} id={id} />
                    </Col>
                </Row>
            </Container>
            <Footer locale={locale} />
        </main>
    );
};

export default ServiceRoute;
