import Landing from '../components/landing';
import Header from '../components/frontend/header';
import Footer from '../components/frontend/footer';
import { Col, Container, Row } from 'react-bootstrap';
import { t } from '../data/localization';

const IndexRoute = ({ locale = 'en' }) => {
    return (
        <main role="main" className="frontend">
            <Header locale={locale} />
            <Container>
                <Row className="justify-content-md-center">
                    <Col lg={8}>
                        <p className="text-end">
                            <a href={t('ALT_LINK', locale)}>{t('ALT_LANG', locale)}</a>
                        </p>
                        <Landing locale={locale} />
                    </Col>
                </Row>
            </Container>
            <Footer locale={locale} />
        </main>
    );
};

export default IndexRoute;
