import { sendRequest } from '../data/api';

export const BASE_URL = '/admin/services/';
const API_URL = 'services';

export const getService = (id) => {
    return sendRequest(API_URL + '/' + id);
};

export const getServices = () => {
    return sendRequest(API_URL);
}

export const deleteService = (id) => {
    return sendRequest(API_URL + '/' + id, 'DELETE');
};

export const createService = (service) => {
    return sendRequest(API_URL, 'POST', service);
};

export const updateService = (id, service) => {
    return sendRequest(API_URL + '/' + id, 'PUT', service);
};

export const getDefaultServiceState = (calendar) => {
    return {
        calendarIds: [],
        enabled: false,
        isPublic: false,
        title: '',
        titleFr: '',
        description: '',
        descriptionFr: '',
        landingPage: '',
        landingPageFr: '',
        emailContent: '',
        emailContentFr: '',
        confirmationPage: '',
        confirmationPageFr: '',
        minDaysAhead: 1,
        maxDaysAhead: 30,
        defaultClientCount: 1,
        defaultDuration: [],
        fields: calendar.fields,
        form: [],
        consentRequired: false,
        removePhoneDoNotCall: false,
        removePhoneAltDoNotCall: false,
	removePhoneReminderType: false,
	removePrintButton: false
    };
};

export const getListingFields = () => {
    return [
        {
            name: 'id',
            label: 'ID',
            type: 'text',
            isPrimaryKey: true
        },
        {
            name: 'title',
            label: 'Title',
            type: 'text'
        },
        {
            name: 'isPublic',
            label: 'Public',
            type: 'checkbox'
        },
        {
            name: 'enabled',
            label: 'Enabled',
            type: 'checkbox'
        },
        {
            name: 'createdAt',
            label: 'Created',
            type: 'datetime'
        },
        {
            name: 'updatedAt',
            label: 'Last Update',
            type: 'datetime'
        }
    ];
};
