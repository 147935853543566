import { Alert } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import BaseForm from '../components/base-form';
import { getServices } from '../data/public';
import { t } from '../data/localization';
import { useNavigate } from 'react-router-dom';

const Landing = ({ locale = 'en' }) => {
    const navigate = useNavigate();
    const basePath = '/' + locale;
    const [showAlert, setShowAlert] = useState(false);
    const [variant, setVariant] = useState('warning');
    const [message, setMessage] = useState('');
    const [formState, setFormState] = useState({ consent: false, serviceId: null });
    const [services, setServices] = useState(null);
    const consentFields = [
        {
            type: 'custom',
            element: <>
                <p className="my-4">{t('LANDING_1', locale)}</p>
                <p>{t('LANDING_2', locale)}</p>
            </>
        },
        {
            name: 'consent',
            label: <strong>{t('LANDING_CONSENT', locale)}</strong>,
            placeholder: '',
            type: 'checkbox',
            required: true
        }
    ];
    const [fields, setFields] = useState(consentFields);

    useEffect(() => {
        if (services && services.length > 0) {
            const getDescription = () => {
                if (formState.serviceId !== null && formState.serviceId !== '') {
                    const selectedOption = services.find(x => x.id === parseInt(formState.serviceId));
                    return selectedOption.description;
                }

                return '';
            };

            const serviceFields = [
                {
                    name: 'serviceId',
                    type: 'select',
                    label: t('Select service:', locale),
                    required: true,
                    options: services.map(x => {
                        return {
                            label: x.title,
                            value: x.id
                        };
                    })
                },
                {
                    type: 'custom',
                    element: <p><em>{getDescription()}</em></p>
                }
            ];
            setFields(serviceFields);
            setFormState({...formState, serviceId: services[0].id});
        }
    }, [services, formState.serviceId]);

    const handleSubmit = () => {
        if (formState.serviceId) {
            navigate(basePath + '/service/' + formState.serviceId);
        } else if (formState.consent && !services) {
            getServices(locale).then(res => {
                setServices(res.items);
            }).catch(e => {
                setMessage(e.toString());
                setVariant('danger');
                setShowAlert(true);
            });
        }
    };

    const showForm = () => {
        return (
            <BaseForm
                fields={fields}
                formState={formState}
                setFormStateCallback={newState => setFormState(newState)}
                submitButtonLabel={t('Next', locale)}
                validFormCallback={(_) => handleSubmit()} />
        );
    };

    const showNoServices = () => {
        return (
            <p className="text-center">
                <strong>
                    {t('There are no services currently available.', locale)}
                </strong>
            </p>
        );
    };

    return (
        <>
            <h1 className="text-center">{t('Appointment Booking', locale)}</h1>
            <Alert className="text-center" show={showAlert} variant={variant}>{message}</Alert>
            {services !== null && services.length < 1 ? showNoServices() : showForm()}
        </>
    );
};

export default Landing;
