import { Col, Row, Table, ProgressBar } from 'react-bootstrap';
import Capacity from './capacity';
import { MONTH_NAMES, WEEKDAYS } from '../data/utils';

const CalendarDates = ({
    startDate = null,
    endDate = null,
    calendarDates = [],
    dateSelectedCallback,
    colSizes = { sm: 12, lg: 12, xl: 12 },
    shortWeekDays = false,
    selectedDate = null,
    selectedDates = [],
    rowHeight = 3,
    multiSelectMode = false,
    baseUrl = null }) => {

    const getMonth = (year, month) => {
        const date = new Date(year, month);
        const firstDay = parseInt(date.getDay());
        const lastDay = new Date(year, month + 1, 0).getDate() + firstDay;
        let days = [];
        let week = [];

        for (let i = 1; i <= 42; i++) {
            let obj = false;
            if (i > firstDay && i <= lastDay) {
                const isoDate = new Date(year, month, i - firstDay).toISOString().split('T')[0];
                obj = {
                    date: isoDate,
                    label: parseInt(isoDate.split('-')[2]).toString(),
                    items: calendarDates.filter(x => {
                        return x.date == isoDate;
                    })
                };
            }
            week.push(obj);

            if (i % 7 === 0 || i === 42) {
                days.push(week);
                week = [];
            }
        }

        return days;
    };

    const getMonths = () => {
        let months = [];
        let currentDate = new Date(startDate.getTime());

        while (currentDate <= endDate) {
            const month = {
                label: MONTH_NAMES[currentDate.getMonth()] + ' ' + currentDate.getFullYear(),
                days: getMonth(currentDate.getFullYear(), currentDate.getMonth())
            };
            months.push(month);
            currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
        }

        return months;
    };

    const handleDateClick = (e, data) => {
        e.preventDefault();
        dateSelectedCallback(data);
    };

    const renderDay = (day, index) => {
        if (!day) {
            return <td key={index}>&nbsp;</td>;
        }

        let cellContent = <span>{day.label}</span>;

        if (multiSelectMode || day.items.length > 0) {
            cellContent = (
                <span>
                    <a href={baseUrl ? baseUrl + '&date=' + day.date : ''} onClick={(e) => handleDateClick(e, day.date)}>{day.label}</a><br />
                    {day.items.length > 0 ? day.items.map((x, i) => <Capacity value={x.capacity} key={i} />) : ''}
                </span>
            );
        }

        return (
            <td className={(multiSelectMode && selectedDates.includes(day.date)) || (day.date === selectedDate) ? 'bg-info' : ''} key={index}>
                {cellContent}
            </td>
        );
    };

    return (
        <Row>
            {getMonths().map((month, index) => {
                return (
                    <Col key={index} sm={colSizes.sm} lg={colSizes.lg} xl={colSizes.xl}>
                        <h4 className="text-center">{month.label}</h4>
                        <Table responsive bordered size="sm">
                            <thead>
                                <tr>
                                    {WEEKDAYS.map((x, i) => <th key={i}>{shortWeekDays ? x.charAt(0) : x}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {month.days.map((week, index) =>
                                    <tr style={{ height: rowHeight + 'rem' }} key={index}>
                                        {week.map((day, index) => renderDay(day, index))}
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Col>
                );
            })}
        </Row>
    );
};

export default CalendarDates;
