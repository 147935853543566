import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Button } from 'react-bootstrap';
import BaseList from '../components/base-list';
import { BASE_URL, deleteService, getServices, getListingFields } from '../data/service';
import TopNav from '../components/top-nav';

const ServiceListRoute = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [variant, setVariant] = useState('');
    const [items, setItems] = useState([]);
    const [fields, setFields] = useState([]);

    const handleEdit = (item) => {
        navigate(BASE_URL + item['id'] + '/edit');
    };

    const handleDelete = (itemToDelete) => {
        const id = itemToDelete['id'];
        deleteService(id).then(data => {
            setVariant('success');
            setMessage('The service has been deleted.');
            setShowAlert(true);
            const newItems = items.filter(item => itemToDelete !== item);
            setItems(newItems);
        });
    };

    useEffect(() => {
        getServices().then((response) => {
            setFields(getListingFields());
            setItems(response.items);
        });
        if (state) {
            setMessage(state.message);
            setVariant(state.variant);
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 5000);
        }
    }, []);

    return (
        <>
            <TopNav activeItem="Services" />
            <h1>Services</h1>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            <BaseList
                fields={fields}
                items={items}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
            />
            <Button onClick={() => navigate(BASE_URL + 'new')}>Add New Service</Button>
        </>
    );
};

export default ServiceListRoute;
