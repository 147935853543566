import moment from 'moment';
import { useState } from 'react';
import { Button, Modal, Row, Table } from 'react-bootstrap';
import { formatTime } from '../data/utils';

const BaseList = ({ fields, items, handleEdit = false, handleCopy = false, handleDelete = false, handleSelect = false, customColumns = [], emptyMessage = 'No records found.' }) => {
    const [modalShow, setModalShow] = useState(false);
    const [currentItem, setCurrentItem] = useState();
    const getFieldValue = (field, value) => {
        let fieldValue = value;

        if (typeof field.aroundHook === 'function') {
            return field.aroundHook(fieldValue);
        }

        if (typeof field.preHook === 'function') {
            fieldValue = field.preHook(fieldValue);
        }

        if (field.type === 'datetime') {
            const date = moment(new Date(value)).format('lll');
            fieldValue = date;
        }

        if (field.type === 'time') {
            return formatTime(value);
        }

        if (field.type === 'select') {
            if (typeof field.options === 'undefined') {
                return false;
            }
            const option = field.options.find(option => option.value === value);
            fieldValue = option.label;
        }

        if (field.type === 'checkbox') {
            fieldValue = value ? 'Yes' : 'No';
        }

        if (field.type === 'multiselect') {
            fieldValue = field.options
                .filter(option => value.includes(option.value))
                .map(option => option.label)
                .join(', ');
        }

        if (typeof field.postHook === 'function' && typeof fieldValue !== 'undefined') {
            fieldValue = field.postHook(fieldValue);
        }

        return fieldValue;
    };

    const renderCustomColumnHeaders = () => {
        if (!customColumns) {
            return '';
        }

        return customColumns.map((column, index) => <td key={index}>{column.label}</td>);
    };

    const renderCustomColumns = (item) => {
        if (!customColumns) {
            return '';
        }

        return customColumns.map((column, index) => {
            return <td key={index}>{column.render(item)}</td>;
        });
    };

    const DeleteConfirmationDialog = (props) =>
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Confirmation
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Do you really want to delete this item?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => { props.onHide(); handleDelete(currentItem); }}>Delete</Button>
                <Button onClick={props.onHide}>Cancel</Button>
            </Modal.Footer>
        </Modal>;

    if (!items.length) {
        return <p>{emptyMessage}</p>;
    }

    return (
        <>
            <Row>
                <Table striped bordered hover responsive="sm">
                    <thead>
                        <tr>
                            {handleEdit ? <td>Edit</td> : ''}
                            {handleCopy ? <td>Copy</td> : ''}
                            {fields.map((field, index) =>
                                <td key={index}>{field.label}</td>
                            )}
                            {renderCustomColumnHeaders()}
                            {handleDelete ? <td>Delete</td> : ''}
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => (
                            <tr key={index}>
                                {handleSelect ?
                                    <td>
                                        <Button className="w100" onClick={() => handleSelect(item)} variant="primary">Select</Button>
                                    </td> : <></>}
                                {handleEdit ? <td>
                                    {item.id ? <Button className="w100" onClick={() => handleEdit(item)} variant="primary">Edit</Button> : ''}
                                </td> : <></>}
                                {handleCopy ? <td>
                                    <Button className="w100" onClick={() => handleCopy(item)} variant="primary">Copy</Button>
                                </td> : <></>}
                                {fields.map((field, index) =>
                                    <td key={index}>{getFieldValue(field, item[field.name])}</td>
                                )}
                                {renderCustomColumns(item)}
                                {handleDelete ?
                                    <td>
                                        <Button onClick={() => { setCurrentItem(item); setModalShow(true); }} variant="danger">Delete</Button>
                                    </td>
                                    : <></>}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>
            <DeleteConfirmationDialog show={modalShow} onHide={() => setModalShow(false)} />
        </>
    );
};

export default BaseList;
