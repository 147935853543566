import { sendRequest } from './api';

const BASE_URL = 'calendars';
const BATCH_URL = 'batch/calendars/';

export const getDefaultLaneState = () => {
    return {
        name: 'Staff 1',
        startTime: '08:45:00',
        endTime: '15:00:00',
        durations: [{
            value: 900,
            label: "15 minutes",
        }],
        slotInterval: 900,
        bookableSlots: [],
        breakSlots: []
    }
};

export const getCalendarDefaultState = () => {
    return {
        name: "",
        description: "",
        note: null,
        categoryId: null,
        locationId: null,
        enabled: false,
	groupSessionEnabled: false,
        showOnDashboard: true,
        syncEnabled: true,
        lockTime: 300,
        fields: [],
        lanes: [getDefaultLaneState()],
        emailsEnabled: false,
        notificationEmailTemplateId: 1,
        reminderEmailTemplateId: 1,
        reminderThreshold: 2
    }
};

export const getCalendar = (id, params) => {
    const url = BASE_URL + '/' + id + (params ? '?' + new URLSearchParams(params).toString() : '');
    return sendRequest(url);
};

export const getCalendars = (params = null) => {
    const url = BASE_URL + (params ? '?' + new URLSearchParams(params).toString() : '');
    return sendRequest(url);
}

export const deleteCalendar = (id) => {
    return sendRequest(BASE_URL + '/' + id, 'DELETE');
};

export const createCalendar = (calendar) => {
    return sendRequest(BASE_URL, 'POST', calendar);
};

export const updateCalendar = (id, calendar) => {
    return sendRequest(BASE_URL + '/' + id, 'PUT', calendar);
};

export const createCalendarDates = (id, params) => {
    return sendRequest(BATCH_URL + id + '/dates', 'POST', params);
};

export const editCalendarDates = (id, params) => {
    return sendRequest(BATCH_URL + id + '/dates', 'PUT', params);
};

export const updateCalendarDates = (id, params) => {
    return sendRequest(BATCH_URL + id + '/dates/patch', 'PUT', params);
};

export const deleteCalendarDates = (id, params) => {
    return sendRequest(BATCH_URL + id + '/dates', 'DELETE', params);
};

export const getCalendarDates = (calendarId, startDate = null, endDate = null) => {
    const params = {
        startDate: startDate ? startDate.toISOString().split('T')[0] : '',
        endDate: endDate ? endDate.toISOString().split('T')[0] : ''
    };
    const urlParams = new URLSearchParams(params).toString();
    const url = BASE_URL + '/' + calendarId + '/dates?' + urlParams;
    const response = sendRequest(url, 'GET');

    return response;
};

export const getCalendarDate = (calendarId, date, timeslots = false) => {
    const url = BASE_URL + '/' + calendarId + '/dates/' + date + (timeslots ? '?timeslots=1' : '');
    const response = sendRequest(url, 'GET');

    return response;
};

export const updateCalendarDate = (calendarId, date, calendarDate) => {
    return sendRequest(BASE_URL + '/' + calendarId + '/dates/' + date, 'PUT', calendarDate);
};

export const formatCalendarTitle = (calendar) => {
    return calendar.location.name + ' - ' + calendar.name;
};

export const getCalendarUsers = (id) => {
    const url = BASE_URL + '/' + id + '/users';
    return sendRequest(url);
}

export const updateCalendarUsers = (calendarId, users) => {
    const data = {
        users: users
    };
    return sendRequest(BASE_URL + '/' + calendarId + '/users', 'PUT', data);
};
