import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import './index.css';
import App from './App';
import IndexRoute from './routes/index-route';
import Login from './routes/login';
import LocationListRoute from './routes/location-list-route';
import LocationNewRoute from './routes/location-new-route';
import LocationEditRoute from './routes/location-edit-route';
import DashboardRoute from './routes/dashboard-route';
import CalendarListRoute from './routes/calendar-list-route';
import CategoryListRoute from './routes/category-list-route';
import CategoryNewRoute from './routes/category-new-route';
import CategoryEditRoute from './routes/category-edit-route';
import CalendarNewRoute from './routes/calendar-new-route';
import CalendarEditRoute from './routes/calendar-edit-route';
import CalendarCopyRoute from './routes/calendar-copy-route';
import ServiceListRoute from './routes/service-list-route';
import ServiceEditRoute from './routes/service-edit-route';
import BookingManagerRoute from './routes/booking-manager-route';
import CalendarDateEditRoute from './routes/calendar-date-edit-route';
import CalendarDatesRoute from './routes/calendar-dates-route';
import CalendarDatesBatchRoute from './routes/calendar-dates-batch-route'
import CalendarUsersRoute from './routes/calendar-users-route';
import HelpRoute from './routes/help-route';
import PasswordReset from './routes/password-reset';
import PasswordResetRequest from './routes/password-reset-request';
import SearchRoute from './routes/search-route';
import DataExporterRoute from './routes/data-exporter-route';
import ReportRoute from './routes/report-route';
import ServiceRoute from './routes/service-route';
import TemplateListRoute from './routes/template-list-route';
import TemplateNewRoute from './routes/template-new-route';
import TemplateEditRoute from './routes/template-edit-route';
import UserRoute from './routes/user-route';
import UserListRoute from './routes/user-list-route';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/admin/login" element={<Login />} />
                <Route path="/admin/password-reset-request" element={<PasswordResetRequest />} />
                <Route path="/admin/password-reset/:code" element={<PasswordReset />} />
                <Route path="/admin" element={<App />}>
                    <Route index element={<DashboardRoute />} />
                    <Route path="locations">
                        <Route index element={<LocationListRoute />} />
                        <Route path="new" element={<LocationNewRoute />} />
                        <Route path=":id/edit" element={<LocationEditRoute />} />
                    </Route>
                    <Route path="categories">
                        <Route index element={<CategoryListRoute />} />
                        <Route path="new" element={<CategoryNewRoute />} />
                        <Route path=":id/edit" element={<CategoryEditRoute />} />
                    </Route>
                    <Route path="booking" element={<BookingManagerRoute />} />
                    <Route path="calendars">
                        <Route index element={<CalendarListRoute />} />
                        <Route path="new" element={<CalendarNewRoute />} />
                        <Route path=":id/edit" element={<CalendarEditRoute />} />
                        <Route path=":id/copy" element={<CalendarCopyRoute />} />
                        <Route path=":id/dates" element={<CalendarDatesRoute />} />
                        <Route path=":id/dates/:date/edit" element={<CalendarDateEditRoute />} />
                        <Route path=":id/dates/batch/add" element={<CalendarDatesBatchRoute mode="Add" />} />
                        <Route path=":id/dates/batch/edit" element={<CalendarDatesBatchRoute mode="Edit" />} />
                        <Route path=":id/users" element={<CalendarUsersRoute />} />
                    </Route>
                    <Route path="services">
                        <Route index element={<ServiceListRoute />} />
                        <Route path="new" element={<ServiceEditRoute />} />
                        <Route path=":id/edit" element={<ServiceEditRoute />} />
                    </Route>
                    <Route path="help" element={<HelpRoute />} />
                    <Route path="search" element={<SearchRoute />} />
                    <Route path="templates">
                        <Route index element={<TemplateListRoute />} />
                        <Route path="new" element={<TemplateNewRoute />} />
                        <Route path=":id/edit" element={<TemplateEditRoute />} />
                    </Route>
                    <Route path="export">
                        <Route index element={<DataExporterRoute />} />
                    </Route>
                    <Route path="report">
                        <Route index element={<ReportRoute />} />
                    </Route>
                    <Route path="users">
                        <Route index element={<UserListRoute />} />
                        <Route path="new" element={<UserRoute />} />
                        <Route path=":id/edit" element={<UserRoute />} />
                    </Route>
                </Route>
                <Route path="/">
                    <Route index element={<IndexRoute locale={"en"} />} />
                    <Route path="fr" element={<IndexRoute locale={"fr"} />} />
                </Route>
                <Route path=":locale/service/:id" element={<ServiceRoute/>} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
