const translations = {
    en: {
        'HOME_LINK': '/',
        'ALT_LINK': '/fr',
        'ALT_LANG': 'Français',
        'EOHU_LOGO': 'EOHU Logo',
        'LANDING_1': 'Personal or personal health information on this form is collected under the authority of the Health Protection and Promotion Act, R.S.O. 1990 (or appropriate Act) and applicable privacy legislation. This information will be used for booking an appointment for public health services and may be used for evaluation or statistical purposes. Any questions about the collection of this information should be directed to the Eastern Ontario Health Unit, 1000 Pitt Street, Cornwall, ON K6J 5T1 or call 613-933-1375 or 1-800-267-7120.',
        'LANDING_2': 'You must consent to the collection and use of your personal health information by the Eastern Ontario Health Unit (EOHU) to continue.',
        'LANDING_CONSENT': 'I consent to the collection and use of my personal health information by the Eastern Ontario Health Unit (EOHU).',
        'FOOTER_COPYRIGHT': '© EOHU 2024. All Rights Reserved.',
        'FOOTER_LINK': 'Click <a href="https://eohu.ca/en/contact-us">here</a> to contact us.'
    },
    fr: {
        'HOME_LINK': '/fr',
        'ALT_LINK': '/',
        'ALT_LANG': 'English',
        'BSEO_LOGO': 'Logo du BSEO',
        'LANDING_1': "Les renseignements – personnels ou sur la santé – dans ce formulaire sont recueillis en vertu de la Loi sur la protection et la promotion de la santé, L.R.O. 1990 (ou la loi appropriée) et la législation applicable sur la protection de la vie privée. Cette information sera utilisée dans la prestation de programmes et de services de santé publique et peut être utilisée à des fins d'évaluation ou statistiques. Toute question concernant la collecte de ces renseignements doit être adressée au Bureau de santé de l'est de l'Ontario, au 1000, rue Pitt, Cornwall, ON K6J 5T1 ou en appelant au 613-933-1375 ou 1-800-267-7120.",
        'LANDING_2': "Vous devez consentir à la collecte et à l'utilisation de vos renseignements personnels sur la santé que fait le Bureau de santé de l'est de l'Ontario (BSEO) pour continuer.",
        'LANDING_CONSENT': 'Je consens à la collecte et à l’utilisation de mes renseignements personnels sur la santé que fait le Bureau de santé de l’est de l’Ontario (BSEO).',
        'FOOTER_COPYRIGHT': '© BSEO 2024. Tous droits réservés.',
        'FOOTER_LINK': 'Cliquer <a href="https://eohu.ca/en/contact-us">ici</a> pour nous contacter.',
        'Step %s of': 'Etape %s de',
        'Next': 'Suivant',
        'Back': 'Précédent',
        'Time Left:': 'Temps restant :',
        'Appointment Booking': 'Prise de rendez-vous',
        'Select service:': 'Sélectionner un service :',
        'Select an option': 'Veuillez choisir une option',
        'Select a clinic:': 'Sélectionner une clinique :',
        'Select a date:': 'Sélectionner une date :',
        'Select a time:': 'Sélectionner une heure :',
        'Please fill all required fields below (fields marked by an * are mandatory and must be completed).': 'Veuillez remplir tous les champs requis ci-dessous (les champs marqués d’une * sont obligatoires et sont à remplir) :',
        'Date and Time:': 'Date et heure :',
        'at': 'à',
        'First name': 'Prénom',
        'Enter first name': 'Entrez le prénom',
        'Last name': 'Nom',
        'Enter last name': 'Entrez le nom',
        'Preferred name (if different from what appears on health card)': 'Nom de choix (s’il est différent du nom qui apparaît sur la carte santé)',
        'Preferred pronoun': 'Pronom de choix',
        'Enter preferred name': 'Entrez le nom de choix',
        'Select preferred pronoun': 'Veuillez choisir le pronom de choix',
        'He': 'Il',
        'She': 'Elle',
        'They': 'Iel',
        'Date of birth': 'Date de naissance',
        'Enter a date of birth': 'Entrez une date de naissance',
        'Legal guardian/substitute decision maker first and last name (if applicable)': 'Nom et prénom du tuteur légal/substitut à la prise de décision (le cas échéant)',
        "Enter first and last name": 'Entrez le nom et prénom',
        'Preferred language': 'Langue de choix',
        'Select preferred language': 'Veuillez choisir la langue de choix',
        'English': 'anglais',
        'French': 'français',
        'Primary phone number': 'Numéro de téléphone principal',
        'Enter primary phone number': 'Veuillez entrer le numéro de téléphone principal',
        'Secondary phone number': 'Numéro de téléphone secondaire',
        'Enter secondary phone number': 'Veuillez entrer le numéro de téléphone secondaire',
        'To receive a confirmation and reminder of your appointment by email, please provide an email address:': 'Veuillez fournir une adresse pour recevoir par courriel une confirmation et un rappel pour votre rendez-vous :',
        'Enter a valid email': 'Veuillez entrer un courriel valide',
        'By providing an email address, you consent to receive communications by email.': 'En fournissant votre adresse courriel, vous consentez à recevoir des communications par courriel.',
        'The phone number and email address provided will only be used for the purpose of the appointment.': 'Le numéro de téléphone et l’adresse courriel fournis ne seront utilisés qu’à des fins de rendez-vous.',
        'Before proceeding with booking, you must agree to the following:': 'Avant de procéder avec la prise de rendez-vous, vous devez accepter les conditions suivantes :',
        'I attest that, to the best of my knowledge and belief, all information provided is accurate. If the information provided cannot be verified or is inaccurate, this may result in cancellation of the appointment.': "J'atteste qu'au meilleur de ma connaissance, tous les renseignements fournis sont exacts. Le rendez-vous pourrait être annulé si les renseignements ne peuvent pas être vérifiés ou s’ils s’avèrent inexacts",
        'I consent to receive communications by phone.': 'J’accepte de recevoir des communications par téléphone.',
        'Proceed with Booking': 'Procéder à la prise de rendez-vous',
        'Print': 'Imprimer',
        'There are no services currently available': 'Aucun service disponible.',
        'Individual accompanying client is NOT the parent or legal guardian': 'La personne accompagnant le client N’est PAS le parent ou le tuteur legal',
        'Do Not Call Primary Number': 'Ne pas appeler au numéro primaire',
        'Do Not Call Secondary Number': 'Ne pas appeler au numéro secondaire',
        'No reminder': 'Aucun rappel',
        'Voice - Primary Phone Number': 'numéro primaire',
        'SMS - Primary Phone Number': 'numéro primaire',
        'Voice - Secondary Phone Number': 'numéro secondaire',
        'SMS - Secondary Phone Number': 'numéro secondaire',
        'Voice - Alternate Phone Number': 'autre numéro',
        'SMS - Alternate Phone Number': 'autre numéro',
        'Select a value': 'Sélectionner une option'
    }
};

export const t = (text, locale = 'en', ...replaceStrings) => {
    let i = 0;
    if (typeof translations[locale][text] === 'undefined') {
        return text.replace(/%s/g, () => replaceStrings[i++]);;
    }

    const translation = translations[locale][text];
    return translation.replace(/%s/g, () => replaceStrings[i++]);
};
