import { t } from '../../data/localization';
import { Parser } from 'html-to-react';
import { Card } from 'react-bootstrap';

const Footer = ({ locale = 'en' }) => {
    return (
        <footer className="footer">
            <Card>
                <Card.Header className="py-0">
                    {t('FOOTER_COPYRIGHT', locale)}
                    &nbsp;{Parser().parse(t('FOOTER_LINK', locale))}
                </Card.Header>
            </Card>
        </footer>
    );
};

export default Footer;
